import React from 'react';
import { withPrefix } from 'gatsby'
import {useIntl, FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl";
import SEO from "../components/seo";
import Layout from '../components/Layout';
import scrollTo from 'gatsby-plugin-smoothscroll';

// ASSETS

import ImgAboutRead from '../images/about/about-read.jpg';
import ImgAboutWrite from '../images/about/about-write.jpg';
import ImgAboutWriteIntro from '../images/about/about-write-intro.jpg';
import ImgAboutPrint from '../images/about/about-print.jpg';
import ImgAboutPrintIntro from '../images/about/about-print-intro.jpg';

const About = (props) => {

    const intl = useIntl();
    
    return (
        <Layout location={props.uri}>
            <div className="page__container-inner">

                <SEO 
                    title={intl.formatMessage({ id: "title.about" })}
                    description={intl.formatMessage({ id: "description.about" })}
                    lang={intl.locale}
                />

                <div className="page__sections-container">

                    <div className="page__section page__section--first about-intro">
                        
                        <article className="container">

                            <div className="about-intro__list">

                                <div className="about-intro__list-item">

                                    <div className="about-intro__list-item-link" onClick={() => scrollTo('#read')}>
                                        
                                        <h2 className="about-intro__list-item-title"><FormattedHTMLMessage id="about.intro-read-title" /></h2>

                                        <div className="about-intro__image-container-outer">
                                            <span className="icon-chevron-down"></span>
                                            <div className="about-intro__image-container-inner">
                                                <img className="about-intro__image" src={ImgAboutRead} alt="Great people read books" />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="about-intro__list-item">

                                    <div className="about-intro__list-item-link" onClick={() => scrollTo('#write')}>
                                        
                                        <h2 className="about-intro__list-item-title"><FormattedHTMLMessage id="about.intro-write-title" /></h2>

                                        <div className="about-intro__image-container-outer">
                                            <span className="icon-chevron-down"></span>
                                            <div className="about-intro__image-container-inner">
                                                <img className="about-intro__image" src={ImgAboutWriteIntro} alt="Great people write books" />
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="about-intro__list-item">

                                    <div className="about-intro__list-item-link" onClick={() => scrollTo('#print')}>
                                        
                                        <h2 className="about-intro__list-item-title"><FormattedHTMLMessage id="about.intro-print-title" /></h2>
                                        <div className="about-intro__image-container-outer">
                                            <span className="icon-chevron-down"></span>
                                            <div className="about-intro__image-container-inner">
                                                <img className="about-intro__image" src={ImgAboutPrintIntro} alt="We love to print books" />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </article>

                    </div>

                    <div className="page__section about-read" id="read">

                        <article className="split-screen container">

                            <div className="split-screen__item about-read__image-container">

                                <img className="about-read__image" src={ImgAboutRead} alt="Publikum: Great people read books" />

                            </div>

                            <header className="split-screen__item about-read__main-content">

                                <h1 className="about-read__title"><FormattedHTMLMessage id="about.intro-read-title" /></h1>

                                <div className="about-read__text-container">
                                    <p className="about-read__text"><FormattedMessage id="about.intro-read-p1" /></p>

                                    <p className="about-read__text"><FormattedMessage id="about.intro-read-p2" /></p>

                                </div>

                            </header>

                        </article>
                        
                    </div>


                    <div className="page__section about-write" id="write">

                        <article className="split-screen about-write__container">

                            <div className="split-screen__item about-write__image-container">

                                <img className="about-write__image" src={ImgAboutWrite} alt="Publikum: Great people write books" />

                            </div>

                            <header className="split-screen__item about-write__main-content">

                                <h1 className="about-write__title"><FormattedHTMLMessage id="about.intro-write-title" /></h1>

                                <div className="about-write__text-container">
                                    <p className="about-write__text"><FormattedMessage id="about.intro-write-p1" /></p>
                                </div>

                            </header>

                        </article>
                        
                    </div>

                    <div className="page__section about-print" id="print">
                            
                        <article className="about-print__container">
                            
                            <header className="about-print__intro">
                                <h2 className="about-print__title"><FormattedHTMLMessage id="about.intro-print-title" /></h2>
                            </header>
                            
                            <div className="about-print__image-container">
                                <img className="about-print__image" src={ImgAboutPrint} alt="Publikum: We love to print books!" />
                            </div>

                        </article>
                        
                    </div>

                    <div className="page__section about-outro">

                        <article className="split-screen about-outro__container">

                            <header className="split-screen__item about-outro__title-container">
                                
                                <h2 className="about-outro__title"><FormattedMessage id="about-outro-title-read" /></h2>

                                <h2 className="about-outro__title"><FormattedMessage id="about-outro-title-write" /></h2>

                                <h2 className="about-outro__title about-outro__title--alt"><FormattedHTMLMessage id="about-outro-title-print" /></h2>

                            </header>

                            <div className="split-screen__item about-outro__main">
                                
                                <div className="about-outro__text-container">

                                    <p className="about-outro__text"><FormattedMessage id="about-outro-p1" /></p>

                                    <p className="about-outro__text"><FormattedMessage id="about-outro-p2" /></p>

                                    <p className="about-outro__text about-outro__text--alt"><FormattedMessage id="about-outro-p3" /></p>

                                </div>

                            </div>

                        </article>
                        
                    </div>

                </div>

            </div>
        </Layout>
    );

}

export default About;
